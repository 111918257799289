import Header from '../components/Header.js';
import SectionAbout from '../components/section/SectionAbout.js';
import SectionService from '../components/section/SectionService.js';
import Configuracoes from '../Configuracoes.js';
export default class About {
    constructor() {
      this.configuracoes = Configuracoes.getInstance();
        this.testimonialsModal = null;
       
        this.sectionAbout = new SectionAbout();
        this.sectionService = new SectionService();
        // this.sectionTestimonials = new SectionTestimonials();
        // this.sectionClients = new SectionClients();
    }
    afterRender(){
        // this.testimonialsModal = new TestimonialsModal();
    }
    loadStyles() {
        const styles = [
          { id: 'about-styles', href: './assets/css/about.css' },
          { id: 'clients-styles', href: './assets/css/clients.css' },
          { id: 'service-styles', href: './assets/css/service.css' },
          { id: 'testemonials-styles', href: './assets/css/testemonials.css' },
          { id: 'modal-styles', href: './assets/css/modal.css' },
          { id: 'responsive-styles', href: './assets/css/responsive.css' }
        ];
        styles.forEach(style => {
          if (!document.getElementById(style.id)) {
            const link = document.createElement('link');
            link.id = style.id;
            link.href = style.href;
            link.type = 'text/css';
            link.rel = 'stylesheet';
            document.head.appendChild(link);
          }
        });
      }
    async render(){
      const i18n = this.configuracoes.i18n;
      this.header = new Header(i18n.links.about);
        //this.loadStyles();
        // const clientsSection = await this.sectionClients.renderAsync();
        const sectionService = await this.sectionService.renderAsync();
        // const sectionTestimonials = await this.sectionTestimonials.renderAsync();
        return `
            ${this.header.render()}
            ${this.sectionAbout.render()}
            ${sectionService}
    
        `;
    }
  }