import FetchData from '../lib/FetchData.js';
import SkillList from '../lists/SkillList.js';
import Configuracoes from '../../Configuracoes.js';
export default class SectionSkill {
  constructor() {
    this.skill = [];
    this.url = './assets/js/json/skills.json';
    this.configuracoes = Configuracoes.getInstance();
  }

  async loadSkill() {
    if (urlsJson.urls[6]) {
      this.skill = urlsJson.urls[6];
    } else {
      this.skill = await FetchData.getJSON(this.url);
      if (!urlsJson.urls) {
          urlsJson.urls = [];
      }
      urlsJson.urls[6] = this.skill;
    }
  }

  async render() {
    const i18n = this.configuracoes.i18n;
    const skillsPromises = this.skill.map(skls => {
        const lista = new SkillList(skls);
        return lista.render(); 
    });

    return Promise.all(skillsPromises).then(skillElements => {
        const skillList = skillElements.join('');

        return `
        <section class="skill">
          <h3 class="h3 skills-title">${i18n.skill}</h3>
          <ul class="skills-list content-card">
          ${skillList} 
          </ul>
       
        `;
    });
}

  async renderAsync() {
    await this.loadSkill();
    return this.render();
  }

}
