import Configuracoes from '../../Configuracoes.js';
export default class SidebarInfo {
  constructor() {
    this.configuracoes = Configuracoes.getInstance();
  }
    render() {
      const i18n = this.configuracoes.i18n;
      return `
        <div class="sidebar-info">
          <figure class="avatar-box">
            <img src="./assets/images/my-avatar.webp" alt="Rodrigo Faustino" width="100px" height="100px">
          </figure>
          <div class="info-content">
            <h1 class="name" title="Rodrigo Faustino">Rodrigo Faustino</h1>
            <p class="title">${i18n.funcao}</p>
          </div>
          <button class="info_more-btn" data-sidebar-btn>
            <span>${i18n.mostrardetalhes}</span>
            <i class="fa-solid fa-chevron-down"></i>
          </button>
        </div>
      `;
    }
  
  }
  