
import Navbar from './Navbar.js';
import '../css/app.css';
import '../css/fontsOff.css';
import '../css/responsive.css';
import '../css/blog.css';
import '../css/clients.css';
import '../css/modal.css';
import '../css/floatButtom.css';
import '../css/about.css';
import '../css/resume.css';
import '../css/service.css';
import '../css/navbar.css';
import '../css/portfolio.css';
import '../css/sidebar.css';
import '../css/transformations.css';

class App{
    constructor(){
        this.Navbar = new Navbar();
        this.init()
    }
    async init(){
        const nav = document.getElementById("nav");
        await this.Navbar.loadMenu();
        nav.innerHTML = this.Navbar.render();
        this.Navbar.afterRender();
        this.Navbar.navigator();

    }
    
}
const app = new App();
window.addEventListener("hashchange", () => app.Navbar.navigator(location.hash));