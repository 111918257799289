import FetchData from '../lib/FetchData.js';
import ExperienceList from '../lists/ExperienceList.js';
import EducationList from '../lists/EducationList.js';
import Configuracoes from '../../Configuracoes.js';
export default class SectionTimeline {
  constructor() {
    this.experience = [];
    this.education = [];
    this.urlEdu = './assets/js/json/education.json';
    this.urlXp = './assets/js/json/experience.json';
    this.configuracoes = Configuracoes.getInstance();
  }

  async loadXP() {
    if (urlsJson.urls[3]) {
      this.experience = urlsJson.urls[3];
    } else {
      this.experience = await FetchData.getJSON(this.urlXp);
      if (!urlsJson.urls) {
          urlsJson.urls = [];
      }
      urlsJson.urls[3] = this.experience;
    }
  }
  async loadEdu() {
    if (urlsJson.urls[2]) {
      this.education = urlsJson.urls[2];
    } else {
      this.education = await FetchData.getJSON(this.urlEdu);
      if (!urlsJson.urls) {
          urlsJson.urls = [];
      }
      urlsJson.urls[2] = this.education;
    }
  }

  async renderLists(list, ListClass) {
    const promises = list.map(item => new ListClass(item).render());
    const elements = await Promise.all(promises);
    return elements.join('');
  }

  async render() {
    await this.loadXP();
    await this.loadEdu();
    const i18n = this.configuracoes.i18n;
    const xpList = await this.renderLists(this.experience, ExperienceList);
    const eduList = await this.renderLists(this.education, EducationList);

    return `
      <section class="timeline">
        <div class="title-wrapper">
          <div class="icon-box">
          <img src="./assets/images/icon-design.svg" alt="design icon" width="40">
          </div>
          <h3 class="h3">${i18n.education}</h3>
        </div>
        <ol class="timeline-list">${eduList}</ol>
      </section>
      <section class="timeline">
        <div class="title-wrapper">
          <div class="icon-box">
          <img src="./assets/images/icon-dev.svg" alt="Web development icon" width="40">
          </div>
          <h3 class="h3">${i18n.experience}</h3>
        </div>
        <ol class="timeline-list">${xpList}</ol>
      </section>
    `;
  }

  async renderAsync() {
    await this.loadXP();
    return this.render();
  }
   
}
