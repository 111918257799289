import FetchData from '../lib/FetchData.js';
import ServiceList from '../lists/ServiceList.js';
import Configuracoes from '../../Configuracoes.js';
export default class SectionService {
    constructor() {
        this.services = [];
        this.url = './assets/js/json/Services.json';
        this.configuracoes = Configuracoes.getInstance();
      }
    
      async loadServices() {
        if (urlsJson.urls && urlsJson.urls[5]) {
          this.services = urlsJson.urls[5];
        } else {
          this.services = await FetchData.getJSON(this.url);
          if (!urlsJson.urls) {
              urlsJson.urls = [];
          }
          urlsJson.urls[5] = this.services;
        }
      }
      async render() {
        const i18n = this.configuracoes.i18n;
        const servicesPromises = this.services.map(post => {
            const lista = new ServiceList(post);
            return lista.render(); 
        });
    
        return Promise.all(servicesPromises).then(serviceElements => {
            const serviceList = serviceElements.join('');
    
            return `
            <section class="service">
                <h3 class="h3 service-title">${i18n.servicetitle}</h3>
                <ul class="service-list">
                ${serviceList} 
                </ul>
            </section>
            `;
        });
    }
    
      async renderAsync() {
        await this.loadServices();
        return this.render();
      }
    
}
