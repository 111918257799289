import Header from '../components/Header.js';
import SectionTimeline from '../components/section/SectionTimeline.js';
import SectionSkill from '../components/section/SectionSkill.js';
import Configuracoes from '../Configuracoes.js';
export default class Resume {
  constructor() {
    this.configuracoes = Configuracoes.getInstance();
    this.sectionTimeline = new SectionTimeline();
    this.sectionSkill = new SectionSkill();
    
  }
  afterRender(){
  }
  loadStyles() {
    const styles = [
      { id: 'resume-styles', href: './assets/css/resume.css' },
      { id: 'responsive-styles', href: './assets/css/responsive.css' }
    ];
    styles.forEach(style => {
      if (!document.getElementById(style.id)) {
        const link = document.createElement('link');
        link.id = style.id;
        link.href = style.href;
        link.type = 'text/css';
        link.rel = 'stylesheet';
        document.head.appendChild(link);
      }
    });
  }
  async render(){
    const i18n = this.configuracoes.i18n;
    this.header = new Header(i18n.resume);
    //this.loadStyles();
    const sectionService = await this.sectionSkill.renderAsync();
    const sectionTimeline= await this.sectionTimeline.renderAsync();
      return `
      ${sectionService}
      ${this.header.render()}
      ${sectionTimeline}
      `;
  }
}